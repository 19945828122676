
import { Options, Vue } from 'vue-class-component';
import ProviderSettings from '@/components/ProviderSettings/ProviderSettings.vue';

@Options({
    name: 'ProviderSettingsEdit',
    components: {
        ProviderSettings
    },
    data() {
        return {};
    },
    computed: {}
})
export default class ProviderSettingsEdit extends Vue {
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
